import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./pages/default.exports";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme.config";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
