import React from "react";
import { Routes as Pages, Route, Navigate } from "react-router-dom";
import {
  AppLayout,
  AuthLayout,
  Employees,
  Home,
  Profile,
  Signin
} from "./default.exports";

type Props = {};

export const app_routes = {
  root: {
    pathname: "/"
  },
  home: {
    pathname: "home",
    relative_pathname: "/home"
  },
  profile: {
    pathname: "profile",
    relative_pathname: "/profile"
  },
  employees: {
    pathname: "employees",
    relative_pathname: "/employees"
  },
  auth_layout: {
    pathname: "/auth",
    relative_pathname: "/auth"
  },
  signin: {
    pathname: "signin",
    relative_pathname: "/auth/signin"
  }
};

const Routes = (props: Props) => {
  return (
    <div>
      <Pages>
        <>
          {/* app routes */}
          <Route path={"/"} element={<AppLayout />}>
            <Route index element={<Navigate to={"home"} />} />
            <Route path={"home"} element={<Home />} />
            <Route path={"employees"} element={<Employees />} />
            <Route path={"profile"}>
              <Route index element={<Navigate to={"/home"} />} />
              <Route path={":uid"} element={<Profile />} />
            </Route>
          </Route>
          {/* auth routes */}
          <Route path={"/auth"} element={<AuthLayout />}>
            <Route index element={<Navigate to={"signin"} />} />
            <Route path={"signin"} element={<Signin />} />
          </Route>
        </>
      </Pages>
    </div>
  );
};

export default Routes;
