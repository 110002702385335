import { Refresh, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, Box, Button, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SelfiePlaceholder } from "../../assets/default.exports";
import { Toast } from "../../components/Alerts/alertservice";
import BasicHeader from "../../components/Headers/BasicHeader";
import { PunchEnum } from "../../constants/enums";
import { auth } from "../../firebase";
import {
  getEmployeeDetails,
  getAllUsersAttendance,
  getAllEmployeesData
} from "../../firebase/functions";
import { getUserFromLocalStorage } from "../../firebase/localStorage";

type Props = {};

const Employees = (props: Props) => {
  const currentUser = getUserFromLocalStorage();
  const [isLoading, setIsLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Employee Id",
      flex: 1
    },
    {
      field: "profile",
      headerName: "Profile picture",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Avatar alt={params?.row?.employeeName} src={params.value} />
          </div>
        );
      }
    },
    {
      field: "employeeName",
      headerName: "Employee name",
      flex: 1
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1
    }
  ];

  const fetchAttendanceAsync = async () => {
    const employeesData = await getAllEmployeesData(auth?.currentUser);
    let rowsEle: any = [];

    employeesData.forEach((data: any) => {
      if (data?.role === "employee") {
        rowsEle.push({
          id: data?._id,
          profile: data?.photoURL,
          employeeName: `${data?.first_name} ${data?.last_name}`,
          email: data?.email
        });
      }
    });

    setRows(rowsEle);
    setIsLoading(false);
  };

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  React.useEffect(() => {
    setIsLoading(true);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const getAdminCredentials = await getEmployeeDetails(user);

        if (!(getAdminCredentials.role === "admin")) {
          Toast.fire({
            icon: "error",
            title: "Only admins have the access"
          });
          await getAuth().signOut();
        } else {
          fetchAttendanceAsync();
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (refresh > 0) {
      fetchAttendanceAsync();
    }
  }, [refresh]);

  return (
    <div>
      <BasicHeader
        isGoBack={true}
        goBackAction={() => {
          navigate(-1);
        }}
        title="Employees"
        fixed={true}
        menuButton={true}
      />
      <div className="p-4 mt-4 flex justify-between">
        <Typography variant="h4" fontWeight={"bold"}>
          Employees list
        </Typography>
        <LoadingButton
          loading={isLoading}
          loadingPosition="start"
          startIcon={<Refresh />}
          variant="outlined"
          onClick={() => {
            const timeStamp = new Date().getTime();
            setRefresh(timeStamp);
          }}
        >
          Refresh
        </LoadingButton>
      </div>
      <div className="p-4">
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight={true}
          pageSize={8}
          rowsPerPageOptions={[5]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 100 }
            }
          }}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "date",
                  sort: "desc"
                }
              ]
            }
          }}
          checkboxSelection={false}
        />
      </div>
    </div>
  );
};

export default Employees;
