import { User } from "firebase/auth";

export const setUserToLocalStorage = (user: User) => {
  localStorage.setItem("currentUser", JSON.stringify(user.providerData[0]));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("currentUser");
};

export const getUserFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("currentUser") || "null");
};
