import { onAuthStateChanged } from "firebase/auth";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import BasicLoader from "../../components/Loaders/BasicLoader";
import { auth } from "../../firebase";
import {
  removeUserFromLocalStorage,
  setUserToLocalStorage
} from "../../firebase/localStorage";
import { app_routes } from "../Routes";

type Props = {};

const AuthLayout = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading...");

  React.useEffect(() => {
    setIsLoading(true);
    // checks currently signed in user
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoading(false);
        setUserToLocalStorage(user);

        navigate(app_routes?.home.relative_pathname);
      } else {
        setIsLoading(false);
        removeUserFromLocalStorage();
      }
    });
  }, []);
  return (
    <div>
      <Outlet />
      {isLoading && <BasicLoader loadingMessage={loadingMessage} />}
    </div>
  );
};

export default AuthLayout;
