import React from "react";
import { InfinitySpin } from "react-loader-spinner";

type Props = {
  loadingMessage: string;
};

const BasicLoader = (props: Props) => {
  return (
    <div className="bg-black backdrop-blur-sm bg-opacity-[.8] text-white flex flex-col items-center justify-center fixed top-0 left-0 right-0 bottom-0 z-20">
      <InfinitySpin width="200" color="#ff3939" />
      <div className="font-bold">{props.loadingMessage}</div>
    </div>
  );
};

export default BasicLoader;
