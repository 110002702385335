import { LocationCity } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { onAuthStateChanged } from "firebase/auth";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SelfiePlaceholder } from "../../assets/default.exports";
import BasicHeader from "../../components/Headers/BasicHeader";
import { PunchEnum } from "../../constants/enums";
import { auth } from "../../firebase";
import {
  getAttendanceByUserId,
  getEmployeeDetails
} from "../../firebase/functions";
import ImageViewer from "awesome-image-viewer";
import { format } from "date-fns";

type Props = {};

const Profile = (props: Props) => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [employee, setEmployee] = React.useState<any>();
  const [images, setImages] = React.useState<any>([]);
  const [showImages, setShowImages] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const columns: GridColDef[] = [
    {
      field: "selfie",
      headerName: "Selfie",
      type: "string",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            <img
              src={params.value}
              onClick={(e) => {
                let image = {
                  mainUrl: e.currentTarget.getAttribute("src")
                };
                setImages([{ ...image }]);
                setShowImages(!showImages);
              }}
              height={60}
              width={60}
              className={
                "h-[40px] w-[40px] rounded-full object-cover cursor-pointer"
              }
              alt=""
            />
          </div>
        );
      }
    },
    {
      field: "date",
      headerName: "Date",
      type: "string",
      flex: 1
    },
    {
      field: "time",
      headerName: "Time",
      type: "string",
      flex: 1
    },
    {
      field: "transaction",
      headerName: "Transaction type",
      type: "string",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            fontWeight={"bold"}
            className={`${
              params.value === PunchEnum.checkin
                ? "text-green-500"
                : "text-red-500"
            } capitalize`}
          >
            {params.value}
          </Typography>
        );
      }
    },
    {
      field: "location",
      headerName: "View Location",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            <Button
              onClick={() => {
                window.open(
                  `https://maps.google.com/maps?q=${params.value.lat},${params.value.lng}&hl=es&z=14&amp;output=embed`,
                  "_blank"
                );
              }}
            >
              <LocationCity /> View Location
            </Button>
          </div>
        );
      }
    }
  ];

  React.useEffect(() => {
    if (uid) {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const employeeDetails = await getEmployeeDetails(user, uid);
          const employeeAttendance = await getAttendanceByUserId(user, uid);
          setEmployee(employeeDetails);

          let rowData: any = [];
          employeeAttendance.forEach((data: any, index: number) => {
            const transactionDate = format(
              new Date(data?.date),
              "dd/MM/yyyy p"
            );

            rowData.push({
              id: index,
              selfie: data?.selfieURL,
              date: transactionDate,
              time: new Date(data?.time).toLocaleTimeString(),
              transaction: data?.punch_type,
              location: {
                lat: data?.coords?.lat,
                lng: data?.coords?.lng
              }
            });
          });

          setRows(rowData);
        }
      });
    }
  }, [uid]);

  React.useEffect(() => {
    if (showImages) {
      new ImageViewer({
        images: images
      });
    }
  }, [showImages]);

  return (
    <div>
      <BasicHeader
        isGoBack={true}
        goBackAction={() => {
          navigate(-1);
        }}
        title="Profile"
        fixed={true}
        menuButton={true}
      />
      <div className="mx-auto p-4">
        {employee && (
          <div>
            <div className="flex items-center">
              <img
                src={employee?.photoURL}
                height={90}
                width={90}
                onError={(ele) => {
                  ele.currentTarget.src = SelfiePlaceholder;
                }}
                className={
                  "h-[90px] w-[90px] rounded-full object-cover mr-[10px]"
                }
                alt=""
              />
              <div>
                <Typography variant="h4" fontWeight={"bold"}>
                  {employee?.first_name} {employee?.last_name}
                </Typography>
                <Typography variant="h5" fontWeight={"semibold"}>
                  {employee?.email}
                </Typography>
              </div>
            </div>
            <div className="mt-4">
              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight={true}
                pageSize={5}
                rowsPerPageOptions={[5]}
                components={{ Toolbar: GridToolbar }}
                initialState={{
                  sorting: {
                    sortModel: [
                      {
                        field: "date",
                        sort: "desc"
                      }
                    ]
                  }
                }}
                checkboxSelection={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
